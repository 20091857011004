import { useState } from "react";
import "./ScoreBoard.css";
import Podium from "./Podium";
import { useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function ScoreBoard(props) {
  let colorIndex = -1;
  const colors = ["#002B5B", "#264A71", "#4D6A89", "#73899F"];
  const [viewPodium, setViewPodium] = useState(true);

  function sortOnTotal(player1, player2) {
    if (player1.total > player2.total) {
      return 1;
    }
    return -1;
  }

  const sortedPlayers = [...props.model.players].sort(sortOnTotal);

  function resultsTableRow(player) {
    if (colorIndex === 3) {
      colorIndex = -1;
    }
    colorIndex += 1;
    return (
      <tr
        className="tableBody"
        style={{ backgroundColor: colors[colorIndex] }}
        key={player.name}
      >
        <td>{player.name}</td>
        <td>{player.guess}</td>
        <td>{player.score}</td>
        <td>{player.total}</td>
      </tr>
    );
  }

  const phoneView = useMediaQuery("(max-width: 840px)");

  const [nextRound, setNextRound] = useState(false);
  useEffect(() => {
    if (props.model.results === 0) {
      setNextRound(true);
    }
  }, [props.model.results]);

  function startNextRound() {
    props.triggerNextRound();
    props.triggerView("Round");
  }

  function playAgain() {
    props.model.round = 5;
    props.triggerNextRound();
    props.triggerView("Lobby");
  }

  function toHomePage() {
    if (props.hostStatus === true) {
      props.deleteGame();
    } else {
      props.removeUser();
    }
  }

  function cancelPodium() {
    setViewPodium(false);
  }

  // adds the guess to the set of guesses at the end of the round
  //ie same tame as scores are shown
  props.addGuessToSet();

  return (
    <div className="resultsContainer">
      {phoneView ? (
        <div className="finalContainerPhone">
          {props.text === "Final Score" ? (
            <div>
              {viewPodium ? (
                <Podium
                  model={props.model}
                  cancelPodium={cancelPodium}
                  scoreBoard={true}
                />
              ) : (
                <div className="final">
                  <div className="content">
                    <div>
                      <p className="title">{props.text}</p>
                      <table>
                        <tbody>
                          <tr>
                            <th>Player</th>
                            <th>Rare Name</th>
                            <th>Score</th>
                            <th>Total Score</th>
                          </tr>
                          {sortedPlayers.map(resultsTableRow)}
                        </tbody>
                      </table>
                    </div>
                    <div className="rarestNameContainerPhone">
                      <p className="text">Rarest name:</p>
                      <table>
                        <tbody>
                          <tr>
                            <th>Player</th>
                            <th>Rare Name</th>
                            <th>Score</th>
                          </tr>
                          <tr className="table">
                            <td>{props.model.rarest.player}</td>
                            <td>{props.model.rarest.name}</td>
                            <td>{props.model.rarest.score}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="buttonContainer">
                    <button className="blueButton" onClick={toHomePage}>
                      Quit
                    </button>
                    <button className="blueButton" onClick={playAgain}>
                      Play again!
                    </button>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div>
              {props.hostStatus === true ? (
                <div className="notFinal">
                  <div className="content">
                    <p className="title">Results</p>
                    <p className="round">{props.text}</p>
                    <table>
                      <tbody>
                        <tr>
                          <th>Player</th>
                          <th>Rare Name</th>
                          <th>Score</th>
                          <th>Total Score</th>
                        </tr>
                        {sortedPlayers.map(resultsTableRow)}
                      </tbody>
                    </table>
                  </div>
                  <div className="buttonContainer">
                    <button className="blueButton" onClick={startNextRound}>
                      Next round
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  {nextRound ? (
                    <div>{props.triggerView("Round")}</div>
                  ) : (
                    <div className="notFinal">
                      <div className="content">
                        <p className="title">Results</p>
                        <p className="round">{props.text}</p>
                        <table>
                          <tbody>
                            <tr>
                              <th>Player</th>
                              <th>Rare Name</th>
                              <th>Score</th>
                              <th>Total Score</th>
                            </tr>
                            {sortedPlayers.map(resultsTableRow)}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div>
          {props.text === "Final Score" ? (
            <div className="finalContainer">
              {viewPodium ? (
                <Podium model={props.model} cancelPodium={cancelPodium} />
              ) : (
                <div className="final">
                  <div className="content">
                    <div>
                      <p className="title">{props.text}</p>
                      <table>
                        <tbody>
                          <tr>
                            <th>Player</th>
                            <th>Rare Name</th>
                            <th>Score</th>
                            <th>Total Score</th>
                          </tr>
                          {sortedPlayers.map(resultsTableRow)}
                        </tbody>
                      </table>
                    </div>
                    <div className="rarestNameContainer">
                      <p className="text">Rarest name:</p>
                      <table>
                        <tbody>
                          <tr>
                            <th>Player</th>
                            <th>Rare Name</th>
                            <th>Score</th>
                          </tr>
                          <tr className="table">
                            <td>{props.model.rarest.player}</td>
                            <td>{props.model.rarest.name}</td>
                            <td>{props.model.rarest.score}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="buttonContainer">
                    <button className="blueButton" onClick={toHomePage}>
                      Quit
                    </button>
                    <button className="blueButton" onClick={playAgain}>
                      Play again!
                    </button>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div>
              {props.hostStatus === true ? (
                <div className="notFinal">
                  <div className="content">
                    <p className="title">Results</p>
                    <p className="round">{props.text}</p>
                    <table>
                      <tbody>
                        <tr>
                          <th>Player</th>
                          <th>Rare Name</th>
                          <th>Score</th>
                          <th>Total Score</th>
                        </tr>
                        {sortedPlayers.map(resultsTableRow)}
                      </tbody>
                    </table>
                  </div>
                  <div className="buttonContainer">
                    <button className="blueButton" onClick={startNextRound}>
                      Next round
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  {nextRound ? (
                    <div>{props.triggerView("Round")}</div>
                  ) : (
                    <div className="notFinal">
                      <div className="content">
                        <p className="title">Results</p>
                        <p className="round">{props.text}</p>
                        <table>
                          <tbody>
                            <tr>
                              <th>Player</th>
                              <th>Rare Name</th>
                              <th>Score</th>
                              <th>Total Score</th>
                            </tr>
                            {sortedPlayers.map(resultsTableRow)}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
