import { useEffect, useState } from "react";
import "./GameRoundView.css";
import { Text } from "react-native";
import { CountdownCircleTimer } from "react-native-countdown-circle-timer";
import { getNameDetails } from "../api.js";

export default function GameRoundView(props) {
  const [round, setRound] = useState(0);
  const [validText, setValidText] = useState("");

  useEffect(() => {
    setRound(props.model.round);
  }, [props.model.round]);

  async function makeUpdate(nextView, score) {
    await props.updateScore(score);
    nextView();
  }

  async function calculatePoints(result) {
    let score = result.Förnamn.Kvinnor + result.Förnamn.Män;
    if (result.Query_name.length <= 1 || score <= 1) {
      score = 1000;
    }
    makeUpdate(function () {
      props.triggerView("Loader");
    }, score);
  }

  function handleEnter() {
    let guess = document.getElementById("text").value;
    if (props.model.guessedNames.has(guess.toUpperCase())) {
      setValidText("This name has already been guessed");
    } else if (guess !== "") {
      props.makeGuess(guess);
      getNameDetails(guess)
        .then((results) => calculatePoints(results))
        .then(() => {
          // updates the total score of the player in the user information
          if (round === 5) {
            props.updateUserInfo();
          }
        });
    } else {
      setValidText("Please enter your guess");
    }
    if (round >= 5) {
      props.triggerScoreBoard("Final Score", "Play Again!");
    } else {
      props.triggerScoreBoard("Round " + round, "Next round");
    }
  }

  async function timeout() {
    props.makeGuess("");
    makeUpdate(function () {
      props.triggerView("Loader");
    }, 1000).then(() => {
      // updates the total score of the player in the user information
      if (round === 5) {
        props.updateUserInfo();
      }
    });
    if (round >= 5) {
      props.triggerScoreBoard("Final Score", "Play Again!");
    } else {
      props.triggerScoreBoard("Results Round " + round, "Next round");
    }
  }

  //Enter button works as submit
  function handleKeyPress(event) {
    if (event.keyCode === 13 || event.which === 13) {
      event.preventDefault();
      document.getElementById("button").click();
    }
  }

  function toHomePage() {
    if (props.hostStatus === true) {
      props.deleteGame();
    } else {
      props.removeUser();
      props.triggerView("Choose game");
    }
  }

  return (
    <div className="GameRoundContainer">
      <div className="countdown">
        <CountdownCircleTimer
          isPlaying
          duration={45}
          colors={["#002B5B", "#002B5B", "#EA5455", "#EA5455"]}
          colorsTime={[30, 13, 8, 0]}
          size={70}
          strokeWidth={15}
          strokeLinecap="butt"
          trailColor="#ECECEC"
          onComplete={() => {
            timeout();
          }}
        >
          {({ remainingTime }) => (
            <Text
              style={{
                fontFamily: "Inter",
                fontSize: 18,
                fontWeight: 500,
              }}
            >
              {remainingTime}
            </Text>
          )}
        </CountdownCircleTimer>
      </div>
      <div className="titleWrapper">
        <p className="title">Round {round}/5</p>
      </div>

      <div className="enterWrapper">
        <div className="text">
          <p>Enter your rare name:</p>
          <input
            autoComplete="off"
            type="text"
            id="text"
            onKeyDown={handleKeyPress}
          ></input>
        </div>
        <div>{validText}</div>
        <button id="button" className="blueButton" onClick={handleEnter}>
          Enter
        </button>
      </div>
      <div className="quitContainer">
        <a onClick={toHomePage}>Quit</a>
      </div>
    </div>
  );
}
