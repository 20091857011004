import NameSearchView from "../views/NameSearchView";
import NameDataView from "../views/NameDataView";
import { observer } from "mobx-react-lite";
import BasicLoader from "../components/BasicLoader";

export default observer(function NameGame(props) {
  /*
   * The NameSearchPresenter is responsible for:
   * - Managing the state of the name search form
   * - Managing the state of the name info
   * - Passing the state of the name search form and name info to the views
   * - Passing callbacks to the views that allow the views to update the state
   *
   * input: {nameSearchModel}
   */

  function setNameACB(name) {
    /*
     * This function is a callback that is passed to the NameSearchView.
     * It is called when the user submits the name search form.
     */
    props.nameSearchModel.setName(name);
  }
  function getNameInfoACB(name) {
    /*
     * This function is a callback that is passed to the NameSearchView.
     * It is called when the user clicks the "Get Name Info" button.
     * It calls the api for name info in the model.
     */
    props.nameSearchModel.getNameInfo(name);
  }

  return (
    <div>
      <div className="nameSearchView">
        {
          <NameSearchView
            nameSearchModel={props.nameSearchModel}
            setNameACB={setNameACB}
            getNameInfoACB={getNameInfoACB}
          />
        }
        {props.nameSearchModel.nameStatistics ? (
          <NameDataView nameStatistics={props.nameSearchModel.nameStatistics} />
        ) : (
          <p></p>
        )}
        {props.nameSearchModel.presentInfo ? (
          <NameDataView
            key={props.nameSearchModel.nameInfo}
            nameInfo={props.nameSearchModel.nameInfo}
          />
        ) : (
          <BasicLoader title={"Loading fun facts..."} />
        )}
      </div>
    </div>
  );
});
