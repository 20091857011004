import React from "react";
import { GoogleLoginButton } from "react-social-login-buttons";
import "./SignInView.css";

const SignInView = ({ userModel, onFacebookSignIn, onGoogleSignIn }) => {
  if (userModel) {
    return (
      <div className="signInContainer">
        <p className="title">Hello, {userModel.name}! 🙋🏼🙋🏽‍♀️</p>

        <div className="userInfoContainer">
          <p className="userInfo">Wins: {userModel.wins}</p>
          <p className="userInfo">Games Played: {userModel.games}</p>
          <p className="userInfo">
            Best score:{" "}
            {userModel.personalBest ? userModel.personalBest : "N/A"}
          </p>
        </div>
      </div>
    );
  } else {
    // The user is not logged in!
    return (
      <div className="signInContainers">
        <div className="signInContainerGoogle">
          <h1>Sign in</h1>
          <p>If you want to save your score, please sign in!</p>
          <div className="buttonContainer">
            <GoogleLoginButton onClick={onGoogleSignIn}>
              Sign in with Google
            </GoogleLoginButton>
          </div>
        </div>
      </div>
    );
  }
};

export default SignInView;
