import "./BasicLoader.css";

export default function BasicLoader(props) {
  return (
    <div>
      <div className="basicLoaderContainer">
        <p className="title2">{props.title}</p>
        <div className="loader"></div>
      </div>
    </div>
  );
}
