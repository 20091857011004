import OPENAI_API_KEY from "./env.js";
const { OpenAI } = require("openai");

const openai = new OpenAI({
  apiKey: OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});

async function callOpenaiAPI(name) {
  /*
   * This function takes in a name and returns a fun fact about the name.
   *
   * @param {string} name - The name to search for.
   * @return {string} - A few fun facts about the name.
   */

  let message =
    "A brief explanation of fun facts and history about the name " + name;
  const completion = await openai.chat.completions.create({
    messages: [
      {
        role: "system",
        content: "You are an informative website about names.",
      },
      { role: "user", content: message },
    ],
    model: "gpt-3.5-turbo",
  });

  return completion.choices[0];
}

//callOpenaiAPI("Fonzie");
export default callOpenaiAPI;
