import { useEffect } from "react";
import "./Loader.css";
import { useState } from "react";

export default function Loader(props) {
    const [allReady, setAllReady] = useState(false)
    useEffect(() => {
        if (props.model.results >= props.model.players.length) {
            setAllReady(true)
        }
    }, [props.model.results, props.model.players.length])
  return (
    <div>
      {allReady ? (
        props.triggerView("score board")
      ) : (
        <div className="LoaderContainer">
          <p className="title">Waiting for opponents...</p>
          <div className="loader"></div>
        </div>
      )}
    </div>
  );
}
