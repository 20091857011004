import GameCard from "../components/GameCard";
import "./GamesView.css";

export default function GamesView() {
  return (
    <div>
      <div className="GamesViewMain">
        <p className="title">Choose a Game</p>
        <div className="cardContainer">
          <GameCard
            game={"the-name-game"}
            title={"Rare Name Game"}
            description={
              "Invite your friends for a multiplayer game where you all guess the rarest names in Sweden"
            }
            image="../images/TheNameGameImage.svg"
          />
          <GameCard
            game={"search-name"}
            title={"Name Search"}
            description={"Get statistics and fun facts about names in Sweden"}
          />
        </div>
      </div>
    </div>
  );
}
