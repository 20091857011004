import "./AboutUsView.css";
import topImage from "../images/AboutUsTop.svg";
import ProfileCard from "../components/ProfileCard";
import fonzieImage from "../images/fonzie.jpeg";
import linneaImage from "../images/Linnea.jpeg";
import alvaImage from "../images/alva.JPG";
import pappaOchMollyImage from "../images/pappa_och_molly.JPG";

export default function AboutUsView() {
  return (
    <div className="AboutUsContainer">
      <img src={topImage} className="topImage" alt=""></img>
      <p className="title">About Us</p>
      <p className="aboutUsText">
        Get to know the minds behind this app – a collaborative effort by four
        KTH students. United by a common interest in creating enjoyable
        experiences through code, our aim was to create a game suitable for both
        group play with friends and family, as well as solo enjoyment. During
        the creation of The Name Game, we have had the opportunity to hone our
        skills in all aspects of app development. From designing and testing our
        idea in Figma to using React and various APIs to bring our vision to
        life!
      </p>
      <div className="profileCards">
        <ProfileCard
          image={fonzieImage}
          name="Fonzie Forsman"
          programme="Computer Science"
          description="Here is a short description or something"
          linkedIn="https://www.linkedin.com/in/fonzie-forsman-364479161/"
          email="fonzieforsman13@gmail.com"
        />
        <ProfileCard
          image={linneaImage}
          name="Linnéa Johansson"
          programme="Information Technology"
          description="Here is a short description or something"
          linkedIn="https://www.linkedin.com/in/linn%C3%A9ajohansson/"
          email="linneajohansson001@gmail.com"
        />
        <ProfileCard
          image={alvaImage}
          name="Alva Håkansdotter"
          programme="Information Technology"
          description="Here is a short description or something"
          linkedIn="https://www.linkedin.com/in/alva-hakansdotter/"
          email="alva.hakansdotter@gmail.com"
        />
        <ProfileCard
          image={pappaOchMollyImage}
          name="Linus Östlund"
          programme="Machine Learning"
          description="The founding father of LLM Byrån, Linus Östlund is now learning React."
          linkedIn="https://www.linkedin.com/in/linus-%C3%B6stlund-256b6712a/"
          email="linus@llmbyran.ai"
        />
      </div>
    </div>
  );
}
