import "./ProfileCard.css";
import linkedInLogo from "../images/LinkedInLogo.svg";
import mailIcon from "../images/MailIcon.svg";

export default function ProfileCard(props) {
  function handleMailClick() {
    window.open("mailto:" + props.email + "?subject=&body=");
  }

  return (
    <div className="profileCardContaier">
      <div className="topRectangle">
        <img className="profileImage" src={props.image} alt={props.name}></img>
      </div>
      <div className="cardInfo">
        <p className="name">{props.name}</p>
        <div className="bottomText">
          <div className="programmeContainer">
            <p className="programme">{props.programme}</p>
          </div>
        </div>
        <div className="linkContainer">
          <a href={props.linkedIn} target="_blank" rel="noreferrer">
            <img className="link" src={linkedInLogo} alt="LinkedIn"></img>
          </a>
          <img
            className="link"
            src={mailIcon}
            onClick={handleMailClick}
            alt="email"
          ></img>
        </div>
        <div></div>
      </div>
    </div>
  );
}
