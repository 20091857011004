import "./NavBarView.css";

export const NavBarView = ({ onLinkClick, userName, onSignOut }) => {
  return (
    <header className="header">
      <div className="leftSpace" />
      <div style={{ display: "contents" }}>
        <button onClick={() => onLinkClick("/home")}>
          <p>Home </p>
        </button>
        <button onClick={() => onLinkClick("/games")}>
          <p>Games </p>
        </button>
        <button onClick={() => onLinkClick("/about-us")}>
          <p>About Us </p>
        </button>
      </div>
      <div>
        {userName ? (
          <div style={{ display: "inline-flex" }}>
            <button
              className="navBarButton"
              onClick={() => onLinkClick("/sign-in")}
            >
              <p>Profile</p>
            </button>
            <button onClick={onSignOut}>
              <p>Sign out</p>
            </button>
          </div>
        ) : (
          <button onClick={() => onLinkClick("/sign-in")}>
            <p>Sign In </p>
          </button>
        )}
      </div>
    </header>
  );
};
