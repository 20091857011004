import callOpenaiAPI from "../openaiAPI";
import { getNameDetails } from "../api.js";

export default {
  name: "",
  nameInfo: "",
  nameStatistics: "",

  presentInfo: true,

  setName(name) {
    this.name = name;
  },

  getNameStats(name) {
    return getNameDetails(name).then((response) => {
      //check if name exists
      let statsSum =
        response.Förnamn.Män +
        response.Förnamn.Kvinnor +
        response.Tilltalsnamn.Män +
        response.Tilltalsnamn.Kvinnor +
        response.Efternamn;
      //reset name info
      this.nameInfo = "";
      //create name statistics string
      this.nameStatistics =
        "Statistics for the name " +
        response.Query_name +
        " in Sweden: \n" +
        response.Förnamn.Män +
        " men and " +
        response.Förnamn.Kvinnor +
        " women have this name.\n" +
        response.Tilltalsnamn.Män +
        " men and " +
        response.Tilltalsnamn.Kvinnor +
        " women have this as their first name.\n" +
        response.Efternamn +
        " people have this as their last name.\n";
      return statsSum;
    });
  },

  async getNameInfo(name) {
    let isSwedishName = await this.getNameStats(name);
    if (isSwedishName) {
      this.presentInfo = false;
      try {
        const nameInfo = await callOpenaiAPI(name);
        this.nameInfo = nameInfo.message.content;
      } catch (error) {
        this.nameInfo = error.message;
      }
      this.presentInfo = true;
    } else {
      this.nameInfo =
        "There are no documented cases of the name " +
        name +
        " in Sweden. Please search for another name.";
    }
  },
};
