import { createHashRouter, RouterProvider } from "react-router-dom";
import "./ReactRoot.css";
import GamesPresenter from "./presenters/GamesPresenter";
import HomePresenter from "./presenters/HomePresenter";
import AboutUsPresenter from "./presenters/AboutUsPresenter";
import SignInPresenter from "./presenters/SignInPresenter";
import NameGamePresenter from "./presenters/NameGamePresenter";
import NameSearchPresenter from "./presenters/NameSearchPresenter";
import NavBarPresenter from "./presenters/NavBarPresenter";

function ReactRoot(props) {
  const router = createHashRouter([
    {
      path: "*",
    },
    {
      path: "/",
      element: <HomePresenter />,
    },
    {
      path: "home",
      element: <HomePresenter />,
    },
    {
      path: "games",
      element: <GamesPresenter />,
    },
    {
      path: "the-name-game",
      element: (
        <NameGamePresenter
          gameModel={props.gameModel}
          userModel={props.userModel}
          key={props.gameModel.deleted}
        />
      ),
    },
    {
      path: "name-search",
      element: <NameSearchPresenter nameSearchModel={props.nameSearchModel} />,
    },
    {
      path: "about-us",
      element: <AboutUsPresenter />,
    },
    {
      path: "sign-in",
      element: <SignInPresenter userModel={props.userModel} />,
    },
  ]);

  return (
    <>
      <NavBarPresenter userModel={props.userModel} router={router} />
      <RouterProvider router={router} />
    </>
  );
}

export default ReactRoot;
