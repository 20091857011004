import "./NameDataView.css";
//import callOpenaiAPI from "../openaiAPI";

export default function NameData(props) {
  /*
   * The NameData is responsible for:
   * - Displaying the name info or name statistics
   *
   * input: {nameInfo} or {nameStatistics}
   */
  if (props.nameStatistics || props.nameInfo) {
    return (
      <div
        className={props.nameInfo ? "nameInfoContainer" : "nameStatsContainer"}
      >
        <div className="nameData">
          <div className="content">
            {props.nameInfo && (
              <div>
                <p className="title">Fun facts</p>
                {props.nameInfo}
              </div>
            )}
            {props.nameStatistics && (
              <div>
                <p className="title">Name Statistics</p>
                {props.nameStatistics}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}
