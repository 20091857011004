import "./NameGameView.css";
import help from "../images/helpBox.svg";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function NameGameView(props) {
  function handleStartClick() {
    props.triggerEnterName(true, "Enter your own name:", "Create game");
  }
  function handleJoinClick() {
    props.triggerEnterName(false, "Enter game code:", "Continue");
  }

  const phoneView = useMediaQuery("(max-width: 800px)");
  const desktopView = useMediaQuery("(min-width: 800px)");

  function deviceView() {
    if (phoneView) {
      return (
        <div className="phoneViewContainer">
          <div className="buttonContainer">
            <p className="title">Rare Name Game</p>
            <button className="blueButton" onClick={handleStartClick}>
              Start a new game
            </button>
            <button className="blueButton" onClick={handleJoinClick}>
              Join a game
            </button>
            <p className="scrollForHelp">Psst! Need help? Scroll down!</p>
          </div>
          <div className="helpTextPhone">
            <h2>The game:</h2>
            <p>
              In this multiplayer game, your goal is to come up with the rarest
              name possible. Each time you submit a name, you earn points equal
              to the number of real people who share that name.{" "}
            </p>
            <p>
              Flex your imagination as you compete against other players to
              discover the most unique and uncommon names out there. Take risks,
              think outside the box, and strategize to minimize your points. Can
              you find the hidden gems among the countless names in Sweden?
            </p>
            <h2>Scoring:</h2>
            <p>
              In the Name Game your goal is to score as low as possible! The
              lower the score, the rarer the name. So unleash your creativity,
              and outwit your opponents.
            </p>
            <h2>Starting a game:</h2>
            <p>
              By pressing "Start a new game" you become the host of the game
              session. Your friends can join the game session by choosing "Join
              a game" and entering the game code shown on the hosts device.
            </p>
          </div>
        </div>
      );
    }
    if (desktopView) {
      return (
        <div className="desktopContainer">
          <img src={help} alt="Help" className="infoBox"></img>
          <div className="helpText">
            <h2>The game:</h2>
            <p>
              In this multiplayer game, your goal is to come up with the rarest
              name possible. Each time you submit a name, you earn points equal
              to the number of real people who share that name.{" "}
            </p>
            <p>
              Flex your imagination as you compete against other players to
              discover the most unique and uncommon names out there. Take risks,
              think outside the box, and strategize to minimize your points. Can
              you find the hidden gems among the countless names in Sweden?
            </p>
            <h2>Scoring:</h2>
            <p>
              In the Name Game your goal is to score as low as possible! The
              lower the score, the rarer the name. So unleash your creativity,
              and outwit your opponents.
            </p>
            <h2>Starting a game:</h2>
            <p>
              By pressing "Start a new game" you become the host of the game
              session. Your friends can join the game session by choosing "Join
              a game" and entering the game code shown on the hosts device.
            </p>
          </div>
          <p className="title">Rare Name Game</p>
          <button className="blueButton" onClick={handleStartClick}>
            Start a new game
          </button>
          <button className="blueButton" onClick={handleJoinClick}>
            Join a game
          </button>
        </div>
      );
    }
  }

  return <div>{deviceView()}</div>;
}
