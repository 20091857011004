import "./Podium.css";
import React from "react";
import confetti from "canvas-confetti";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function Podium(props) {
  function sortOnTotal(player1, player2) {
    if (player1.total > player2.total) {
      return 1;
    }
    return -1;
  }

  const sortedPlayers = [...props.model.players].sort(sortOnTotal);

  function handleClick() {
    props.cancelPodium();
  }

  const phoneView = useMediaQuery("(max-width: 840px)");

  var end = Date.now() + 1 * 1000;
  var colors = ["#002B5B", "#EA5455"];

  (function frame() {
    confetti({
      particleCount: 3,
      angle: 40,
      spread: 55,
      origin: { x: 0, y: 0.7 },
      colors: colors,
      startVelocity: 60,
    });
    confetti({
      particleCount: 3,
      angle: 140,
      spread: 55,
      origin: { x: 1, y: 0.7 },
      colors: colors,
      startVelocity: 60,
    });

    if (Date.now() < end) {
      requestAnimationFrame(frame);
    }
    document.body.onkeyup = function (e) {
      if ((e.key === " " || e.code === "Space") && props.scoreBoard) {
        end = Date.now() + 1 * 1000;
        if (Date.now() < end) {
          requestAnimationFrame(frame);
        }
      }
    };
  })();

  return (
    <div>
      {sortedPlayers.length >= 3 ? (
        <div className="podiumContainer">
          <p className="title">Well done!</p>
          {phoneView ? (
            <div>
              <div className="placements">
                <div>
                  <p className="name">{sortedPlayers[1].name}</p>
                  <div
                    className="step"
                    style={{ height: "110px", width: "90px", fontSize: "53px" }}
                  >
                    2
                  </div>
                </div>
                <div>
                  <p className="name">{sortedPlayers[0].name}</p>
                  <div
                    className="step"
                    style={{ height: "150px", width: "90px", fontSize: "53px" }}
                  >
                    1
                  </div>
                </div>

                <div>
                  <p className="name">{sortedPlayers[2].name}</p>
                  <div
                    className="step"
                    style={{ height: "90px", width: "90px", fontSize: "53px" }}
                  >
                    3
                  </div>
                </div>
              </div>
              <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
              <div className="base" style={{ width: "80vw" }}></div>
              <div className="base" style={{ width: "90vw" }}></div>
              </div>
            </div>
          ) : (
            <div>
              <div className="placements">
                <div>
                  <p className="name">{sortedPlayers[1].name}</p>
                  <div className="step" style={{ height: "161px" }}>
                    2
                  </div>
                </div>
                <div>
                  <p className="name">{sortedPlayers[0].name}</p>
                  <div className="step" style={{ height: "234px" }}>
                    1
                  </div>
                </div>

                <div>
                  <p className="name">{sortedPlayers[2].name}</p>
                  <div className="step" style={{ height: "125px" }}>
                    3
                  </div>
                </div>
              </div>
              <div className="base" style={{ width: "580px" }}></div>
              <div className="base" style={{ width: "620px" }}></div>
            </div>
          )}

          <button className="blueButton" onClick={handleClick}>
            {" "}
            View all scores
          </button>
        </div>
      ) : (
        <div>{props.cancelPodium()}</div>
      )}
    </div>
  );
}
