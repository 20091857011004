import { useState } from "react";
import "./EnterView.css";

export default function EnterView(props) {
  const [code, setCode] = useState("");
  const [validEnter, setValidEnter] = useState("");

  //Enter button works as submit
  function handleKeyPress(event) {
    if (event.keyCode === 13 || event.which === 13) {
      event.preventDefault();
      document.getElementById("button").click();
    }
  }

  async function handlerEnter() {
    let text = document.getElementById("text").value;

    //Player view when a code is entered after joining a lobby
    if (props.buttonText === "Continue") {
      props.model.checkCode(text.toUpperCase()).then((valid) => {
        if (valid && text !== "") {
          setValidEnter("");
          setCode(text);
          document.getElementById("text").value = props.userModel?.name || ""; // set name to the name of the user if it exists
          props.triggerEnterName(false, "Enter your own name:", "Join game!");
        } else {
          document.getElementById("text").value = "";
          setValidEnter("Invalid code");
        }
      });
    }

    //Player view when a name is entered
    if (props.buttonText === "Join game!") {
      if (text !== "") {
        setValidEnter("");
        let nameAvailable = await props.checkIfAvailableName(text, code);
        if (!nameAvailable) {
          setValidEnter("This name is already taken");
          return;
        }
        props.joinGame(text, code, props.userModel?.uid);
        props.triggerView("Lobby");
      } else {
        setValidEnter("Please enter a name");
      }
    }

    //Host view when a name is entered
    if (props.buttonText === "Create game") {
      if (text !== "") {
        setValidEnter("");
        props.newGame(text, props.userModel?.uid);
        props.triggerView("Lobby");
      } else {
        setValidEnter("Please enter a name");
      }
    }
  }

  return (
    <div className="enterNameContainer">
      <div className="enterNameWrapper">
        <div className="text">
          <p>{props.text}</p>
          <input
            autoComplete="off"
            type="text"
            id="text"
            defaultValue={
              props.text.includes("name") ? props.userModel?.name : ""
            }
            onKeyDown={handleKeyPress}
          ></input>
        </div>
        <div className="validEnterContainer">{validEnter}</div>
        <button
          type="button"
          className="blueButton"
          id="button"
          onClick={handlerEnter}
        >
          {props.buttonText}
        </button>
      </div>
    </div>
  );
}
