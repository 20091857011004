import "./HomeView.css";

export default function HomePageView() {
  function handleClick() {
    window.location.hash = "#/games";
  }

  var img = new Image();
  img.src = "../images/HomePageBackground.svg";
  console.log("complete");
  if (img.complete) {
    console.log("complete");
  } else {
    img.onload = () => {
      console.log("not complete?");
    };
  }

  return (
    <div className="HomePageMain">
      <div className="text">
        <p className="title">The Name Game</p>
        <p className="description">
          Embark on a name trivia adventure with our game, where you and your
          friends can discover fascinating facts about names like Linus or
          Fonzie. Engage in friendly competition, testing your knowledge about
          the uniqueness of names in Sweden. Join the fun and see who among your
          friends is the ultimate name trivia champion!
        </p>
        <button className="blueButton" onClick={handleClick}>
          Start playing
        </button>
      </div>
    </div>
  );
}
