import { useEffect, useState } from "react";
import "./LobbyView.css";

export default function LobbyView(props) {
  const [players, setPlayers] = useState([]);

  //players are updated every 100 seconds to limit requests to firestore. This will be changed
  useEffect(() => {
    setPlayers(props.model.players);
  }, [props.model.players]);

  useEffect(() => {
    if (props.model.gameOn) {
      props.triggerView("Round");
    }
  }, [props.model.gameOn]);

  function handleStart() {
    props.startGame();
    props.triggerView("Round");
  }

  return (
    <div>
      {props.hostStatus ? (
        <div className="lobbyContainer">
          <p className="title2">Game code: </p>
          <p className="code">{props.model.code}</p>
          <p className="title3">Players:</p>
          {players.length > 6 ? (
            <div className="players1">
              {players.map((player) => {
                return <p key={player.name}>{player.name}</p>;
              })}{" "}
            </div>
          ) : (
            <div className="players2">
              {players.map((player) => {
                return <p key={player.name}>{player.name}</p>;
              })}{" "}
            </div>
          )}
          <button className="blueButton" onClick={handleStart}>
            Start
          </button>
        </div>
      ) : (
        <div className="lobbyContainer">
          <p className="title2">Waiting for Game to Start...</p>
          <div>
            <p className="title3">Players:</p>
            {players.length > 6 ? (
              <div className="players1">
                {players.map((player) => {
                  return <p key={player.name}>{player.name}</p>;
                })}{" "}
              </div>
            ) : (
              <div className="players2">
                {players.map((player) => {
                  return <p key={player.name}>{player.name}</p>;
                })}{" "}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
