import "./index.css";
import { createElement } from "react";
import { createRoot } from "react-dom/client";
import ReactRoot from "./ReactRoot.jsx";
import { observable, configure } from "mobx";

import "./teacherFetch.js"; // protection against fetch() in infinite re-render
import gameModel from "./model/gameModel.js";
import userModel from "./model/userModel.js";
import nameSearchModel from "./model/nameSearchModel.js";

import connectToFirebase from "./model/firebaseModel.js";
import connectUserToFirebase from "./model/firebaseUserModel.js";

// No Mobx actions are used
configure({ enforceActions: "never" });

const reactiveGameModel = observable(gameModel);
const reactiveUserModel = observable(userModel);
const reactiveNameSearchModel = observable(nameSearchModel);
window.React = { createElement: createElement };
/*
window.myModel = reactiveGameModel;
window.myModel = reactiveUserModel;
*/

connectToFirebase(reactiveGameModel);
connectUserToFirebase(reactiveUserModel);

createRoot(document.getElementById("root")).render(
  <ReactRoot
    gameModel={reactiveGameModel}
    userModel={reactiveUserModel}
    nameSearchModel={reactiveNameSearchModel}
  />
);
