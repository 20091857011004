// userModel.js
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  getAuth,
  signInWithRedirect,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import {
  modelToPersistence,
  saveToFirebase,
  readFromFirebase,
  db,
} from "./firebaseUserModel";

import { makeAutoObservable } from "mobx";

class UserModel {
  // User-related state
  user = null;
  uid = null;
  name = null;

  // user-related game state
  wins = 0;
  games = 0;
  personalBest = null;

  //bool for suspense when logging
  ready = true;

  constructor() {
    makeAutoObservable(this);
  }

  async signInWithProvider(providerName) {
    const auth = getAuth();

    let provider;
    switch (providerName) {
      case "google":
        // default scopes: 'email' and 'profile'
        provider = new GoogleAuthProvider();
        break;
      case "facebook":
        // no default scopes, setting 'email' and 'public_profile'
        provider = new FacebookAuthProvider();
        provider.addScope("email");
        provider.addScope("public_profile");
        break;
      // Add cases for other providers
      default:
        throw new Error("Unsupported provider: " + providerName);
    }

    // Add any other configurations if necessary
    //await setPersistence(auth, browserSessionPersistence);
    //return signInWithPopup(auth, provider);

    // In memory persistence will be applied to the signed in Google user
    // even though the persistence was set to 'none' and a page redirect
    // occurred.

    signInWithPopup(auth, provider).then((result) => {
      this.setUser(result.user);
      this.loadFromFirebase();
    });
  }

  loadFromFirebase() {
    this.ready = false;
    readFromFirebase(this.uid).then((data) => {
      // if data is not null, then the user is not new
      if (data) {
        this.wins = data.wins;
        this.games = data.games;
        this.personalBest = data.personalBest;
        // if data is null, then the user is new
      } else {
        this.wins = 0;
        this.games = 0;
        this.personalBest = null;
      }
      this.ready = true;
    });
  }

  signOutUser() {
    const auth = getAuth();
    console.log("Signing out user", auth);
    this.setUser(null);
    return signOut(auth);
  }

  setUser(user) {
    this.user = user;
    this.uid = user?.uid;
    this.name = user?.displayName;
  }

  updateUserInfo(score, win) {
    this.games++;
    if (win) {
      this.wins++;
    }
    if (score < this.personalBest || this.personalBest === null) {
      this.personalBest = score;
    }
    saveToFirebase({
      uid: this.uid,
      wins: this.wins,
      games: this.games,
      personalBest: this.personalBest,
    });
  }

  // Other user-related methods
}

const userModel = new UserModel();
export default userModel;
