// hämta namnet från modellen
// passa den till navbaren view

import { NavBarView } from "../views/NavBarView";
import { observer } from "mobx-react-lite";

export default observer(function NavBarPresenter({ userModel, router }) {
  function signOut() {
    /**
     * Sign out the current user.
     * TODO not working >:(
     */
    userModel.signOutUser().catch((error) => {
      console.log(
        "Du, jag krashade i SignInPresenter.jsx när jag skulle logga ut."
      );
    });
  }
  const setLocationHash = (path) => {
    window.location.hash = path;
  };

  return (
    <NavBarView
      onLinkClick={setLocationHash}
      userName={userModel.name ?? null}
      router={router}
      onSignOut={signOut}
    />
  );
});
