import "./GameCard.css";

export default function GameCard(props) {
  function TitleImage() {
    if (props.title === "Rare Name Game") {
      return <div className="nameGame"></div>;
    }
    if (props.title === "Name Search") {
      return <div className="nameSearch"></div>;
    }
  }

  function handleClick() {
    window.location.hash =
      props.game === "the-name-game" ? "the-name-game" : "name-search";
  }

  return (
    <div onClick={handleClick} className="card">
      <TitleImage />
      <p className="gameTitle">{props.title}</p>
      <p className="description">{props.description}</p>
    </div>
  );
}
