import "./NameSearchView.css";
import { useState } from "react";

export default function NameSearchView(props) {
  /*
   * The NameSearchView is responsible for:
   * - Displaying the name search form
   * - Displaying the name info
   * - Calling callbacks when the user interacts with the name search form
   *
   * input: {nameSearchModel, setNameACB, getNameInfoACB}
   */
  const [name, setName] = useState("");

    //Enter button works as submit
    function handleKeyPress(event) {
      if (event.keyCode === 13 || event.which === 13) {
        event.preventDefault();
        document.getElementById("button").click();
      }
    }

  return (
    <div className="nameSearchContainer">
      <form
        className="nameSearchForm"
        onSubmit={() => {
          props.setNameACB(name);
          //prevents spam of API
          if (props.nameSearchModel.presentInfo && name !== "") {
            props.getNameInfoACB(name);
          }
        }}
      >
        <input
          className="nameSearchInput"
          value={name}
          placeholder="Enter a name"
          onChange={(e) => setName(e.target.value)}
          onKeyDown={handleKeyPress}
        />
      </form>
      <div className="buttonContainer">
        <button
          className="blueButton"
          id="button"
          disabled={!props.nameSearchModel.presentInfo || name === ""}
          onClick={() => {
            props.getNameInfoACB(name);
          }}
        >
          Get Name Info
        </button>
      </div>
    </div>
  );
}
