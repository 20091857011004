import NameGameView from "../views/NameGameView";
import EnterView from "../views/EnterView";
import LobbyView from "../views/LobbyView";
import Loader from "../components/Loader";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useEffect } from "react";
import GameRoundView from "../views/GameRoundView";
import ScoreBoard from "../components/ScoreBoard";
import { getAuth, onAuthStateChanged } from "@firebase/auth";
import DeletedGameView from "../views/DeletedGameView";

export default observer(function NameGame(props) {
  const [enterView, setEnterView] = useState({
    status: false,
    hostStatus: false,
    text: "",
    buttonText: "",
  });

  const [resultView, setResultView] = useState({
    status: false,
    text: "",
    buttonText: "",
  });

  const [gameView, setGameView] = useState("Choose game");

  const [deletedGame, setDeletedGame] = useState(false);
  //checks if the game has been deleted by the host
  useEffect(() => {
    if (props.gameModel.deleted === true) {
      setDeletedGame(true);
      if (enterView.hostStatus) {
        setGameView("Choose game");
      } else {
        if (gameView !== "Choose game") {
          setGameView("Deleted game");
        }
      }
    }
  }, [props.gameModel.deleted, enterView.hostStatus]);

  window.onhashchange = function () {
    //remove game if host leaves
    if (enterView.hostStatus === true) {
      deleteGame();
    } else {
      removeUser();
    }
  };

  async function checkCode(startCode) {
    return await props.gameModel.checkCode(startCode);
  }

  async function newGame(name, uid) {
    props.gameModel.newGame(name, uid);
  }

  function joinGame(name, code, uid) {
    props.gameModel.joinGame(name, code, uid);
  }

  async function checkIfAvailableName(name, code) {
    return await props.gameModel.checkName(name, code);
  }

  function startGame() {
    props.gameModel.startGame();
  }

  function makeGuess(guess) {
    props.gameModel.makeGuess(guess);
  }

  async function updateScore(score) {
    await props.gameModel.updateScore(score);
  }

  /* triggerEnterName will change what happens when
   name is entered for host, player or game round
   through if statements in enterView   */
  function triggerEnterName(hostStatus, text, buttonText) {
    setGameView("Enter");
    setEnterView({
      status: true,
      hostStatus: hostStatus,
      text: text,
      buttonText: buttonText,
    });
  }

  function triggerView(view) {
    setGameView(view);
  }

  function triggerNextRound() {
    props.gameModel.nextRound();
  }

  function triggerScoreBoard(text, buttonText) {
    setResultView({
      status: true,
      text: text,
      buttonText: buttonText,
    });
  }

  function removeUser() {
    props.gameModel.removeUser();
  }

  function deleteGame() {
    props.gameModel.deleteGame();
  }

  function updateUserInfo() {
    /**
     * Triggered at the end of a game (round 5)
     * 1. Find the user in the players array
     * 2. Find the best score in the players array
     * 3. If the user has the best score, set win to true
     * 4. Update the user information in the userModel
     */

    //if we have a user logged in
    const uid = props.userModel.uid;
    if (uid) {
      let userPlayer = null;
      let bestScore = null;
      for (const player of props.gameModel.players) {
        //if the player is the user, set userPlayer to that player
        if (player?.uid === uid) {
          userPlayer = player;
        }
        //if the player has a better score than the best/lowest score, set best score to that score
        if (player.total < bestScore || !bestScore) {
          bestScore = player.total;
        }
      }
      const win = userPlayer.total === bestScore;
      props.userModel.updateUserInfo(userPlayer.total, win);
    }
  }

  function addGuessToSet() {
    /**
     * Triggered at the end of a round, ie in score board view
     * Adds the guess to the set of guesses
     */
    props.gameModel.addGuessToSet();
  }
  //persistance
  const auth = getAuth();
  onAuthStateChanged(auth, function (user) {
    if (user) {
      // User is signed in
      props.userModel.setUser(user);
    } else {
      // User is signed out
      props.userModel.setUser(null);
    }
  });

  return (
    <div>
      <div>
        {gameView === "Choose game" && (
          <NameGameView
            model={props.gameModel}
            triggerEnterName={triggerEnterName}
          />
        )}
      </div>
      <div>
        {deletedGame && gameView === "Deleted game" ? (
          <div>{<DeletedGameView triggerView={triggerView} />}</div>
        ) : (
          <div>
            {gameView === "Enter" && (
              <EnterView
                status={enterView.status}
                hostStatus={enterView.hostStatus}
                text={enterView.text}
                buttonText={enterView.buttonText}
                triggerEnterName={triggerEnterName}
                triggerView={triggerView}
                joinGame={joinGame}
                checkCode={checkCode}
                newGame={newGame}
                checkIfAvailableName={checkIfAvailableName}
                model={props.gameModel}
                userModel={props.userModel}
              />
            )}
            {gameView === "Lobby" && (
              <LobbyView
                key={props.gameModel.players}
                hostStatus={enterView.hostStatus}
                model={props.gameModel}
                triggerView={triggerView}
                startGame={startGame}
              />
            )}
            {gameView === "Round" && (
              <GameRoundView
                key={props.gameModel.round}
                model={props.gameModel}
                triggerView={triggerView}
                makeGuess={makeGuess}
                updateScore={updateScore}
                triggerScoreBoard={triggerScoreBoard}
                updateUserInfo={updateUserInfo}
                hostStatus={enterView.hostStatus}
                removeUser={removeUser}
                deleteGame={deleteGame}
              />
            )}
            {gameView === "Loader" && (
              <Loader
                key={props.gameModel.results}
                triggerView={triggerView}
                model={props.gameModel}
              />
            )}
            {gameView === "score board" && (
              <ScoreBoard
                key={props.gameModel.results}
                status={resultView.status}
                text={resultView.text}
                buttonText={resultView.buttonText}
                hostStatus={enterView.hostStatus}
                triggerNextRound={triggerNextRound}
                triggerView={triggerView}
                model={props.gameModel}
                removeUser={removeUser}
                deleteGame={deleteGame}
                scoreBoard={true}
                addGuessToSet={addGuessToSet}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
});
