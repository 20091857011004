import "./DeletedGameView.css";
import image from "../images/GameRemovedImage.svg"

export default function DeletedGameView(props) {
  function waiting() {
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));
    const awaitHome = async () => {
      await delay(8000);
      props.triggerView("Choose game");
    };
    awaitHome();
  }
  return (
    <div className="deletedGameContainer">
      <p className="title">The host has ended this game</p>
      <p>You will soon be redirected home</p>
      <img src={image}></img>
      {waiting()}
    </div>
  );
}
