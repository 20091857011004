import { observer } from "mobx-react-lite";
import SignInView from "../views/SignInView";
import { getAuth, onAuthStateChanged } from "@firebase/auth";
import { useEffect, useState } from "react";
import BasicLoader from "../components/BasicLoader";

export default observer(function SignInPresenter(props) {
  // Observer to detect changes in userModel
  async function signIn(provider) {
    /**
     * Sign in with a provider.
     * @param {AuthProvider} provider
     */
    try {
      console.log("Attempting to sign in with provider:", provider);
      props.userModel.signInWithProvider(provider);
      console.log("Signed in with provider:", provider);
    } catch (error) {
      handleSignInError(error);
    }
  }

  function handleSignInError(error) {
    /**
     * Handle sign-in errors here.
     */
    console.error("Sign-in error:", error);
    // Extract and log specific error details
    const errorCode = error.code;
    const errorMessage = error.message;
    const email = error.customData?.email;
    const credential = error.credential;
    console.error(errorCode, errorMessage, email, credential);
    // TODO Handle error UI feedback here
  }

  function signOut() {
    /**
     * Sign out the current user.
     * TODO not working >:(
     */
    props.userModel.signOutUser().catch((error) => {
      console.log(
        "Du, jag krashade i SignInPresenter.jsx när jag skulle logga ut."
      );
    });
  }
  // Observer to detect changes in userModel
  // Keeps persistanse of user when refreshing page
  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(
      auth,
      function (user) {
        if (user) {
          // User is signed in
          props.userModel.setUser(user);
          props.userModel.loadFromFirebase();
          console.log("user is signed in");
        } else {
          // User is signed out
          props.userModel.setUser(null);
        }
      },
      []
    );
  }, []);

  function handeViewChange() {
    if (props.userModel?.user && props.userModel.ready) {
      return (
        <SignInView
          userModel={props.userModel}
          onFacebookSignIn={() => signIn("facebook")}
          onGoogleSignIn={() => signIn("google")}
          onSignOut={signOut}
        />
      );
    } else if (!props.userModel?.user && props.userModel.ready) {
      return (
        <SignInView
          userModel={null}
          onFacebookSignIn={() => signIn("facebook")}
          onGoogleSignIn={() => signIn("google")}
          onSignOut={signOut}
        />
      );
    } else {
      return <BasicLoader title={"User data"} />;
    }
  }

  return <div>{handeViewChange()}</div>;
});
