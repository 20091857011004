import {
  checkFirebaseCodes,
  readFromFirebase,
  db,
  saveToFirebase,
  updateFirebase,
  deleteGameInFirebase,
} from "./firebaseModel";
import { onSnapshot, doc, arrayRemove } from "firebase/firestore";

export default {
  name: "",
  code: "",
  players: [],
  rarest: { player: "", name: "", score: 0 },
  gameOn: false,
  round: 0,
  results: 0,
  deleted: false,
  guessedNames: new Set(),

  addGuessToSet() {
    this.players.forEach((player) => {
      if (player.guess !== "") {
        this.guessedNames.add(player.guess.toUpperCase());
      }
    });
    console.log(this.guessedNames);
  },

  //Generates a 6 character rondomized code. This will be the id for each game
  generateCode() {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let result = "";
    for (let i = 0; i < 3; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    result += " ";
    for (let i = 0; i < 3; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  },

  checkCode(code) {
    return checkFirebaseCodes(code);
  },

  //Adds the first player to a new game
  newGame(name, uid) {
    this.name = name;
    let code = this.generateCode();
    this.code = code;
    //Creates a new player object with the given name added uid if it exists
    const player = { name: name, guess: "", score: 0, total: 0 };
    if (uid) {
      player.uid = uid;
    }
    this.players = [player];
    this.rarest = { player: "", name: "", score: 0 };
    this.round = 0;
    this.results = 0;
    this.deleted = false;
    this.guessedNames = new Set();
    saveToFirebase({
      code: code,
      players: this.players,
      round: this.round,
      gameOn: false,
      rarest: this.rarest,
      results: this.results,
      deleted: this.deleted,
    });
    this.startListner(code);
  },

  //Checks if a given name is already in the game with the code given
  async checkName(name, code) {
    let available = true;
    let upperCaseCode = code.toUpperCase();
    return readFromFirebase(upperCaseCode).then((model) => {
      model.players.forEach((player) => {
        if (player.name === name) {
          available = false;
        }
      });
      if (!available) {
        return false;
      } else {
        return true;
      }
    });
  },

  //Adds a new player to the game with the given code
  async joinGame(newPlayer, code, uid) {
    let upperCaseCode = code.toUpperCase();
    this.name = newPlayer;
    const player = { name: newPlayer, guess: "", score: 0, total: 0 };
    if (uid) {
      player.uid = uid;
    }
    this.guessedNames = new Set();
    this.startListner(upperCaseCode);
    return readFromFirebase(upperCaseCode).then((model) => {
      updateFirebase(model.code, {
        players: [...model.players, player],
      });
      return true;
    });
  },

  //Called when host starts the game from the lobby
  startGame() {
    if (this.code !== "") {
      updateFirebase(this.code, {
        gameOn: true,
        round: 1,
      });
    }
  },

  //Called after result view when new round is triggered
  nextRound() {
    this.results = 0;
    if (this.round < 5) {
      this.round += 1;
    } else {
      this.round = 1;
      this.guessedNames = new Set();
      this.players.forEach((player) => {
        if (player.name === this.name) {
          player.guess = "";
          player.score = 0;
          player.total = 0;
          updateFirebase(this.code, {
            players: this.players,
            rarest: { player: "", name: "", score: 0 },
          });
        }
      });
    }
    updateFirebase(this.code, {
      round: this.round,
      results: this.results,
    });
  },

  makeGuess(guess) {
    this.players.forEach((player) => {
      if (player.name === this.name) {
        player.guess = guess;
        updateFirebase(this.code, {
          players: this.players,
          gameOn: false,
        });
      }
    });
  },

  updateScore(score) {
    if (this.rarest.score === 0) {
      this.rarest = {
        player: this.players[0].name,
        name: this.players[0].guess,
        score: score,
      };
      updateFirebase(this.code, {
        rarest: this.rarest,
      }).then(() => {
        return true;
      });
    }
    this.players.forEach((player) => {
      if (player.name === this.name) {
        if (score < this.rarest.score) {
          this.rarest = {
            player: player.name,
            name: player.guess,
            score: score,
          };
          this.results += 1;
          player.score = score;
          player.total += score;
          updateFirebase(this.code, {
            players: this.players,
            rarest: this.rarest,
            results: this.results,
          }).then(() => {
            return true;
          });
        } else {
          this.results += 1;
          player.score = score;
          player.total += score;
          updateFirebase(this.code, {
            players: this.players,
            results: this.results,
          }).then(() => {
            return true;
          });
        }
      }
    });
  },

  removeUser() {
    this.players.forEach((player) => {
      if (player.name === this.name) {
        updateFirebase(this.code, {
          players: arrayRemove(player),
        });
      }
    });
  },

  deleteGame() {
    updateFirebase(this.code, { deleted: true });
  },

  //Listens to all changes in firestore
  async startListner(code) {
    if (code !== "") {
      const unsubscribe = onSnapshot(doc(db, "games", code), (snapshot) => {
        if (snapshot?.data()?.players?.length > 0) {
          this.code = snapshot.data().code;
          this.players = snapshot.data().players;
          this.gameOn = snapshot.data().gameOn;
          this.round = snapshot.data().round;
          this.rarest = snapshot.data().rarest;
          this.results = snapshot.data().results;
          this.deleted = snapshot.data().deleted;

          if (snapshot.data().deleted) {
            const awaitRemove = async () => {
              unsubscribe();
              await deleteGameInFirebase(this.code);

              this.code = "";
              this.players = [];
              this.gameOn = false;
              this.round = 0;
              this.rarest = { player: "", name: "", score: 0 };
              this.results = 0;
              this.deleted = false;
            };
            awaitRemove();
          }
        }
      });
    }
  },
};
