import firebaseConfig from "./firebaseConfig.js";
import { initializeApp } from "firebase/app";
import { getDoc, setDoc, getFirestore, updateDoc } from "firebase/firestore";
import { onSnapshot, doc } from "firebase/firestore";
import { reaction } from "mobx";

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

function modelToPersistence(model) {
  return {
    wins: model.wins,
    games: model.games,
    personalBest: model.personalBest,
  };
}

async function saveToFirebase(model) {
  await setDoc(doc(db, "users", model.uid), modelToPersistence(model));
}

async function readFromFirebase(uid) {
  const docRef = doc(db, "users", uid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return null;
  }
}

function connectUserToFirebase(model) {
  function checkForChangeACB() {
    return [model.games, model.personalBest];
  }

  function toFirebaseACB() {
    saveToFirebase(model);
  }
  reaction(checkForChangeACB, toFirebaseACB);
}

export { modelToPersistence, saveToFirebase, readFromFirebase, db };

export default connectUserToFirebase;
