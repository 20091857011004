// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDAmrdVGWUM6mZzLzs3B2-wp77izJKO3Wo",
  authDomain: "dh2642-project-57.firebaseapp.com",
  projectId: "dh2642-project-57",
  storageBucket: "dh2642-project-57.appspot.com",
  messagingSenderId: "392362494422",
  appId: "1:392362494422:web:8613b2be56d99dd1e47c77",
  measurementId: "G-X05VEWKXVZ",
};

export default firebaseConfig;
