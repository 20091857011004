import firebaseConfig from "./firebaseConfig.js";
import { initializeApp } from "firebase/app";
import {
  getDoc,
  setDoc,
  getFirestore,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { onSnapshot, doc } from "firebase/firestore";
import { reaction } from "mobx";

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

function modelToPersistence(model) {
  return {
    code: model.code,
    players: model.players,
    rarest: model.rarest,
    round: model.round,
    gameOn: model.gameOn,
    results: model.results,
    deleted: model.deleted,
  };
}

async function saveToFirebase(model) {
  if (model.players !== undefined) {
    try{
    await setDoc(doc(db, "games", model.code), modelToPersistence(model));
    } catch {
      console.log("Error in saveto")
    }
  }
}

async function updateFirebase(code, change) {
  try {
    await updateDoc(doc(db, "games", code), change);
  } catch {
    console.log("game has been removed");
  }
}

async function deleteGameInFirebase(code) {
  await deleteDoc(doc(db, "games", code));
}

async function readFromFirebase(code) {
  if (code !== "") {
    const docRef = doc(db, "games", code);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return "No such document";
    }
  }
}

async function checkFirebaseCodes(code) {
  function checkResult(result) {
    if (result === "No such document") {
      return false;
    }
    return true;
  }
  return readFromFirebase(code).then(checkResult);
}

async function startOnSnapshot(code) {
  const unsubscribe = onSnapshot(doc(db, "games", code)).then((snapshot) => {
    return snapshot.data();
  });
}

function connectToFirebase(model) {
  function checkForChangeACB() {
    return [model.code];
  }
  function toFirebaseACB() {
    saveToFirebase(model);
  }
  reaction(checkForChangeACB, toFirebaseACB);
}

export {
  modelToPersistence,
  saveToFirebase,
  checkFirebaseCodes,
  readFromFirebase,
  startOnSnapshot,
  updateFirebase,
  deleteGameInFirebase,
  db,
};

export default connectToFirebase;
